.div_sub_section
{
  margin: 20px;
  border-radius: 10px;
  border:  solid 1px rgb(37, 0, 139) ;
  background-color: lavender;
  
}
.div_sub_section_item
{
  margin-left: 0px;
  margin-right: 5px;
  margin-bottom: 10px;
  padding: 0px;
  background-color:rgb(239, 240, 218);
}
.internal_text
{
    /*
    border-left: solid maroon 1px;
    border-right: solid maroon 1px;
    border-bottom: solid maroon 1px;
    */
    padding: 10px;
    margin-left: 0px ;
    margin-right: 0px;
}
.internal_text span{
  font-weight: bold;
  color: black;
}
.back_section
{
  background-color: lavenderblush;
  border: solid rgb(252, 141, 252) 1px;
  border-radius: 10px;
}
.brg_div
{
  margin-left: 0px;
  margin-right: 5px;
  margin-bottom: 10px;
  padding: 0px;
  background-color:rgb(241, 241, 241);
}
.brg_div span{
  font-weight: bold;
}
.tabs-div
{
  text-align: left;
  color: black;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bolder;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  
}
.tabs-div-panel
{
  text-align: left;
  color: black;
  font-family:Verdana, Geneva, Tahoma, sans-serif; 
  font-size: 12px;
}