.div-Vocaulary
{
    
    border-bottom:#78C144 black 0px;
    padding: 10px;
    font-size: 12px;
    margin: 10px;
    background-color: #0057A2;
}
.div-Vocaulary-Text
{
    
    
    font-weight: bolder;
    background-color:	#78C144;
    padding: 10px;
    color: white;
    font-size: 16px;
    
}

.div-Vocaulary-Meaning
{
    padding: 5px;
    font-weight: bolder;
    font-size: 14px;
    vertical-align: top;
    color: yellow;
}
.div-Vocaulary-examples
{
    padding: 5px;
    text-align: left;
    color: white;
    margin: 5px;
}
.div-Vocaulary-image
{
    width: 100px;
    height: 100px;

}
.div-Vocaulary-image-td
{
    background-image: url("../images/logo.svg");
    background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  transform: translate(10%, 20%);
  z-index: 1;
  height: 75px;
  width: 75px;
}
.div-Vocaulary-Meaning-Examples-td
{
    
    vertical-align: top;
}
.div-Vocaulary-Footer-Text
{
    
    
    color: blue;
    font-size: 12px;
    
}