.App {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  background-color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main-div{
  background-color: white; 
  border-left: #00fff2 solid 1px;
  border-bottom: rgb(252, 164, 0)  solid 1px;
  border-right:  #00fff2  solid 1px;
  border-top: #00fff2 solid 10px;
  font-variant-caps: all-petite-caps;
  font-weight: bold;

}
.nouns-div{
  margin-top: 10px;
  background-color: white;
  font-family: 'Varela Round';
  border-left: #1261A0 solid 1px;
  border-bottom: #1261A0 solid 1px;
  border-right: #1261A0 solid 1px;
  border-top: #1261A0 solid 5px;
}
.nouns-heading{
  text-align: left;
  color: blue;
  margin-left: 15px; 
}

.Headline
{
  color: white ; 
  font-family: sans-serif ;
  background: linear-gradient(50deg, #6EC1F8 25%, #FF6424 25%);
  font-weight: bold;
  font-variant-caps: all-petite-caps;
}
.Menu
{
  /*background: #038eff;*/
  /*background-image: linear-gradient(to right, #ad5389, #3c1053);
  background: linear-gradient(to right, #FBFCFC, #22b1b1);
  background: linear-gradient(to right, #4682B4, #4682B4);*/
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bolder;
  font-size: 14px;
  font-variant-caps: all-petite-caps;
  margin-left: 50px;
  margin-right: 0px;
  /*
  background-color: #0057A2;
  */
  z-index: 200;
  border-bottom: 2px solid black;
  

}
.Menu ul{
  display: inline-flex;
  list-style-type: none;
  color: black;
  text-align: center;
  margin-bottom: 0px;
}
.Menu ul li
{
  /*
  width: 150px;
  */
  margin-right: 5px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  /*padding-right: 25px;*/
  background-color: transparent;
  color: #0057A2;
  border-radius: 0px;
  text-align: center;

}
.Menu ul li a{
  text-decoration: none;
  color: black;
  text-align: center;
  
}
.active,.Menu ul li:hover
{ 
  /*background:  #1379cc;*/
  /*background-image: linear-gradient(to right, #00C9FF, #92FE9D);*/
  border-radius: 0px;
  color: white ;
  background-color: skyblue;

}
.Menu ul li a:hover
{ 
  /*background:  #1379cc;*/
  /*background-image: linear-gradient(to right, #00C9FF, #92FE9D);*/
  border-radius: 3px;
  color: white ;

}
.SubMenu
{
  display: none;
}
.Menu ul li:hover .SubMenu
{
  display: block;
  position: absolute;
  /*background: #1261A0;*/
  /*background-image: linear-gradient(to bottom, #ad5389, #3c1053);*/
  
  margin-top: 5px;
  margin-left: -10px;
  text-align: left;
  z-index: 200;

}
.Menu ul li:hover .SubMenu ul
{
  display: block;
  margin-left: 0px;
  padding-left: 0px;
  color: #034e9f;
  /*background-color:  #0057A2;*/
  background-color:  white;
  border: solid black 1px;
  width: 200px;
}
.Menu ul li:hover .SubMenu ul li
{
  padding-top: 5px;
  /*
  border-bottom: 1px solid silver;
  border-right: 1px solid silver;
  */
  text-align: left;
  border-radius: 0px;
  background-color:  transparent;
  margin-right: 0px;
  

  
}
.Menu ul li:hover .SubMenu ul li a
{
  color: black;
  margin-top: 2px;
}
.Menu ul li:hover .SubMenu ul li:hover
{
  background-color: skyblue;
  color: white;
  border-left: solid 2px brown;
}
.Menu ul li:hover .SubMenu ul li a:hover
{
  /*color: rgb(247, 165, 12) ;*/
  color: white;
}
.Menu ul li:hover .SubMenu ul li:last-child{

  border-bottom: none;
}

.SubMenu-1
{
  display: none;
}
.Menu ul li:hover .SubMenu ul li:hover .SubMenu-1
{
  display: block;
  position: absolute;
  /*background: #1261A0;*/
  /*background-image: linear-gradient(to bottom, #ad5389, #3c1053);*/
  
  
  margin-left: 188px;
  margin-top: -25px;
  text-align: left;
  width: 100%;
  

}

.Menu ul li:hover .SubMenu ul li:hover .SubMenu-1 ul
{
  display: block;
  margin-left: 0px;
  padding-left: 0px;
  color: #034e9f;
  /*background-color:  #0057A2;*/
  background-color:  white;
  border: solid black 1px;
}
.Menu ul li:hover .SubMenu ul li:hover .SubMenu-1 ul li
{
  padding-top: 5px;
  /*border-bottom: 1px solid orange;*/
  background: transparent;
  text-align: left;
  border-radius: 0px;
  
}
.Menu ul li:hover .SubMenu ul li:hover .SubMenu-1 ul li a
{
  color: black;
  width: 100%;
}
.Menu ul li:hover .SubMenu ul li:hover .SubMenu-1 ul li:hover
{
  background-color: skyblue;
  color: white;
}
.Menu ul li:hover .SubMenu ul li:hover .SubMenu-1 ul li a:hover
{
  /*color: rgb(247, 165, 12) ;*/
  color: white;
}
.Menu ul li:hover .SubMenu ul li:hover .SubMenu-1 ul li:last-child{

  border-bottom: none;
}
.Menu-Down-Icon
{
  color: black;
  vertical-align: middle;
  font-weight: bold;
  text-align: right;
  padding-left: 5px;
  
}
.Menu-Next-Icon
{
  color: black;
  vertical-align: middle;
  font-weight: bold;
  text-align: right;
  
}


.div_header{

  margin: 10px;
  padding: 10px;
  border-bottom: 2px solid red;
  width: 200px;
  color: #1261A0 ;
  font-variant-caps: all-petite-caps;
  font-weight: bold;
}
.div_header_def{

  margin: 10px;
  padding: 10px;
  width: 200px;
  color: black;
  font-weight: normal;
}
.wrapper-div{
  
  width: 240px;
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
  background: transparent;
  color: blue;
}
.wrapper-div-div
{
  background: transparent;
  text-align: left;
  font-weight: bold;
  

}
.wrapper-div-div ul{
  display: inline-flex;
  list-style-type: none;
  color: white;
}
.wrapper-div-div ul li
{
  width: 340px;
  margin: 10px;
  padding: 10px;

}
.ForwardArrow
{
  vertical-align: middle;
  color: black;
}
.Def_title{
  color: black ; 
  
  /*background-color: #1261A0;*/
  border-top: transparent solid 1px;
  border-bottom: transparent solid 1px;
  font-weight: bold;
  font-variant-caps: all-petite-caps;
  margin-top: 2px;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
 
}
.Def_details{
  color: rgb(46, 10, 206);  
  /*background-color: white;*/
  border-top: transparent solid 1px;
  border-bottom: blue solid 0px;
  font-weight: bolder;
  margin-top: 2px;
  text-align: left;
  padding-left: 50px;
  padding-right: 50px;
  
}
.Def_details_desc{
  color: black;  
  /*background-color: white;*/
  border-top: transparent solid 1px;
  border-bottom: black solid 0px;
  margin-top: 2px;
  text-align: left;
  padding-left: 50px;
  padding-right: 50px;
  font-weight: bold;
}
.Content_desc{
  color: black;  
  background-color: transparent;
  
  margin-top: 0px;
  text-align: left;
  padding-left: 0px;
  padding-right: 0px;
}
.FullTable{
  width: 98%;
  background: linear-gradient(to right, #FBFCFC, #22b1b1);
  margin: 10px;
  border: silver solid 1px;
}
.Types-circle-div{
  width: 50px;
  height: 50px;
  margin: 30px;
  padding: 10px;
  background: gray;
  border-radius: 100px;
  text-align: center;
  align-items: center;
  margin-left: 100px;
}
.Types-div{
  border-bottom: rgb(4, 113, 255) solid 10px;
  border-top: rgb(4, 113, 255) solid 2px;
  border-right: rgb(4, 113, 255) solid 2px;
  border-left: rgb(4, 113, 255) solid 2px;
  width: 240px;
  height: 370px;
  margin: 5px;
  padding: 5px;
  background: white;
}
.Types-div-div
{
  background: white;
  text-align: center;
  font-weight: bold;

}
.Types-div-div ul{
  display: inline-flex;
  list-style-type: none;
  color: white;
}
.Types-div-div ul li
{
  width: 340px;
  margin: 10px;
  padding: 10px;

}
.div_header_types{

  padding: 10px;
  width: 200px;
  color: black;
  text-align: center;
  font-weight: normal;
}
.types_header{

  margin: 10px;
  padding: 10px;
  border-bottom: 2px solid red;
  width: 240px;
  color: #1261A0 ;
  font-variant-caps: all-petite-caps;
  font-weight: bold;
}
.Main_div_content
{
  /*
  margin-left: 50px;
  margin-right: 50px;
  */
  Padding-left: 0px;
  Padding-right: 0px;
  margin-top: 0px;
  margin-bottom: 20px;
  border-radius: 0px;
  /*background-color: white;*/
  /*background: linear-gradient(to right, #acb6e5, #86fde8);*/
  background: linear-gradient(to right, white, white); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border: 1px solid black;
}
.Highlighters
{
  font-weight: bold;
  color: #1261A0;
}
.example_list
{
  background: #038eff;
  text-align: left;
  font-weight: bolder;
  margin: 10px;
  padding: 10px;

}
.example_list ul{
  display:block;
  list-style-type: none;
  color: black;
}
.example_list ul li
{
  width: 150px;
  margin: 5px;
  padding: 5px;
  text-align: left;

}
.MarginNormal
{
  margin: 20px;
  border-radius: 10px;
  border:solid rgb(0, 132, 255) 1px;
  background-color: rgb(226, 239, 255);
}

.Content_sub_desc{
  color: black;  
  background-color: transparent;
  border-top: transparent solid 1px;
  margin: 10px;
  text-align: left;
  padding-left: 15px;
  padding-right: 50px;
  border-bottom: dashed #0f8aee 2px;
  padding-bottom: 10px;

}
.Content_table_desc{
  color: black;  
  background-color: rgb(226, 239, 255);
  border-top: transparent solid 1px;
  border-bottom: transparent solid 1px;
  margin-top: 2px;
  text-align: left;
  padding-left: 15px;
  padding-right: 50px;
}
.Line_style{
  color: #0f8aee;
  margin: 0px;
}
.Table_style{
  width: 700px;
  table-layout: fixed;
  text-align: left;
  margin: 10px;
  padding: 10px;
}
.tabledata_style
{
  padding: 5px;
  margin: 5px;
  background-color: rgb(82, 11, 141);
  color: white;
  font-weight: bolder;
}
.tabledata_style_normal
{
  padding: 5px;
  margin: 5px;
  background-color:rgb(191, 161, 245);
}
.table_header
{
  padding: 10px;
  margin: 10px;
  text-align: center;
  background-color: rgb(82, 11, 141);
  color: white;
  font-weight: bolder;
}
.Proper-types-div{
  border-bottom: rgb(4, 113, 255) solid 10px;
  border-top: rgb(4, 113, 255) solid 2px;
  border-right: rgb(4, 113, 255) solid 2px;
  border-left: rgb(4, 113, 255) solid 2px;
  width: 240px;
  height: 370px;
  margin: 5px;
  padding: 5px;
  background: white;
  border-radius: 10px;
}
.EndofSection
{
  border-bottom: black solid 5px;
}
.back_stage{
  background-color: rgb(16, 20, 26);
  margin: 5px;
  padding: 10px;
}
.Table_2_style{
  width: 700px;
  table-layout: fixed;
  text-align: left;
  margin: 10px;
  padding: 10px;
}
.tabledata_2_style
{
  padding: 5px;
  margin: 5px;
  background-color: navy;
  color: white;
  font-weight: bolder;
}
.tabledata_2_style_normal
{
  padding: 5px;
  margin: 5px;
  background-color: rgb(230, 230, 230);
}
.table_2_header
{
  padding: 10px;
  margin: 10px;
  text-align: center;
  background-color: black;
  color: white;
  font-weight: bolder;
}
.adj_desc{
  color: black;  
  background-color: transparent;
  border-top: transparent solid 1px;
  margin: 10px;
  text-align: left;
  padding-left: 15px;
  padding-right: 50px;
  padding-bottom: 10px;
  border-bottom: solid navy 1px;
}
.adj_desc ul li span{
  font-weight: bold;
}
.align-list{
  text-align: left;
  background-color: 'red';
}
.icon_style{
  color: orangered;
  width: 50px;
  height: 50px;
}
.iconinfo
{
    width: 25px;
    height: 25px;
}
.Tooltip
{
  background-color: #3c1053;
}
.Headericoninfo
{
    width: 10%;
    height: 10%;
    margin: 10px;
    border-radius: 50px;
    
}
.bg-banner
{
  background-image: url("images/bg-banner.svg");
	background-position: center;
  z-index: 1;
  height: 75px;
  
  
  /*
  background-color: #034e9f;
  */
}
.CE-logo{
	background-image: url("images/logo.svg");
	background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  transform: translate(20%, -20%);
  z-index: 200;
  height: 275%;
  width: 112%;
  /*
  width: 125px;
  height: 115px;*/
  
  
  
  /*
  background-color: #034e9f;
  */
}
.log-section {
  /*
  background-color: #034e9f;*/
}
h3.logotitle {
  font-size: 45px;
  text-align: left;
}
.logotitle span em{
  color: #fff;
}
.logotitle em {
  color: #88c23f;
}
.Logo-main-Div
{
  /*background: #038eff;*/
  /*background-image: linear-gradient(to right, #ad5389, #3c1053);
  background: linear-gradient(to right, #FBFCFC, #22b1b1);
  background: linear-gradient(to right, #4682B4, #4682B4);*/
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bolder;
  background-color: white;
  margin-top: 0px;
  /*font-variant-caps: all-petite-caps;*/


  /*
  margin-left: 50px;
  margin-right: 50px;
  */

}
.phone{
	display: inherit;
	margin-right: 20px;
	padding: 5px 0px;
}
.email{
	display: inherit;
	margin-right: 20px;
	padding: 5px 0px;
}
.search{
	padding: 5px 0px;
}
.top-searchbar{
	
  background-color: purple;
  font-size: 12px;
  padding-left: 500px;
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
  color: white;
}
.section-outer-box
{
  padding-left: 75px;
  padding-right: 75px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 10px;
  
  
}
.Def-Title
{
  background-color: blue;
  text-align: left;
  padding-left: 20px;
  padding:10px;
  color: white;
  font-weight: bold;
}
.Def-Title-Next-Icon
{
  color: white;
  vertical-align: middle;
  font-weight: bold;
}
.Def-Details-Outer
{
  background-color: #8FBC8B;
  padding-bottom:10px;
  text-align: left;
}
.Def-Inner-Title{
  color: rgb(46, 10, 206);  
  /*background-color: white;*/
  border-top: transparent solid 1px;
  border-bottom: blue solid 0px;
  font-weight: bolder;
  margin-top: 0px;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  
}
.Def_inner-desc{
  color: black;  
  /*background-color: white;*/
  border-top: transparent solid 1px;
  border-bottom: black solid 0px;
  margin-top: 0px;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  
}
.Nouns-logo{
	background-image: url("images/banners/Nouns.jpg");
	background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 75px;
  z-index: 1;
  
  width: 300px;
  height: 225px;
  
  
  /*
  background-color: #034e9f;
  */
}
.Pronouns-logo{
	background-image: url("images/banners/Pronouns.jpg");
	background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 75px;
  z-index: 1;
  
  width: 300px;
  height: 225px;
}
.Verbs-logo{
	background-image: url("images/banners/Verbs.jpg");
	background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 75px;
  z-index: 1;
  
  width: 300px;
  height: 225px;
}
.Adjectives-logo{
	background-image: url("images/banners/Adjectives.jpg");
	background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 75px;
  z-index: 1;
  
  width: 300px;
  height: 225px;
}
.Conjunctions-logo{
	background-image: url("images/banners/Conjunctions.jpg");
	background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 75px;
  z-index: 1;
  
  width: 300px;
  height: 225px;
}
.Interjections-logo{
	background-image: url("images/banners/Interjections.jpg");
	background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 75px;
  z-index: 1;
  
  width: 300px;
  height: 225px;
}
.Prepositions-logo{
	background-image: url("images/banners/Prepositions.jpg");
	background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 75px;
  z-index: 1;
  
  width: 300px;
  height: 225px;
}
.Adverbs-logo{
	background-image: url("images/banners/Adverbs.jpg");
	background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 75px;
  z-index: 1;
  
  width: 300px;
  height: 225px;
}
.Similes-logo{
	background-image: url("images/banners/Similes.jpg");
	background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 75px;
  z-index: 1;
  
  width: 300px;
  height: 225px;
}
.Content-section
{
  padding-left: 75px;
  padding-top: 20px;
  padding-bottom: 20px;
  
}
.bg-lightgray
{
  background-color: lightgray;
}
.Examples-Outer
{
  background-color: white;
  
  padding:10px;
  text-align: left;
}
.Examples-Title
{
  
  text-align: center;
  padding-left: 20px;
  padding:10px;
  color: white;
  font-weight: bold;
}
.Examples-eg
{
  text-align: left;
  padding-left: 5px;
  padding:5px;
  color: white;
  font-weight: bold;
}

.examples-section
{
  margin-right: 10px;
  margin-top: 10px;
}
.section-inner-box
{
  padding-left: 0px;
  padding-top: 20px;
  margin-bottom: 10px;  
}
.Normal-mp
{
  padding:10px;
  margin: 10px;

}
.Normal-mp-5px
{
  padding:5px;
  margin: 5px;

}
.font-bold
{
  font-weight: bold;
}
.Footer-main-box
{
  padding-left: 75px;
  padding-right: 75px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 10px; 
  background-color: #0057A2;
  color: yellow;
  font-weight: bold;
}
.Footer-inner-box
{
  padding-left: 0px;
  padding-top: 20px;
  margin-bottom: 10px;  
}
.div-list-main{
  background-color: #0057A2;
  margin: 5px;
}
.div-list-header{
  margin: 5px;
  padding: 5px;
  font-weight: bold;
  color: white;
}
.div-list-def{
  margin: 5px;
  padding: 5px;
  color: yellow;
}
.div-list-link{
  padding: 5px;
  background-color: yellowgreen;
  text-align: right;
}
.outer-style
{
  border: #036bc0 solid 2px;
  border-radius: 10px;
  margin-bottom: 25px;
}
.p-m
{
  padding: 10px;
  margin: 5px;
}