.bgcolor-red
{
    background-color: red;
}
.color-red
{
    color: red;
}
.bgcolor-maroon
{
    background-color: maroon;
}
.color-maroon
{
    color: maroon;
}
.bgcolor-silver
{
    background-color: silver;
}
.color-silver
{
    color: silver;
}
.bgcolor-green
{
    background-color: green;
}
.color-green
{
    color: green;
}
.bgcolor-blue
{
    background-color: blue;
}
.color-blue
{
    color: blue;
}
.bgcolor-lavender
{
    background-color: lavender;
}
.color-lavender
{
    color: lavender;
}
.bgcolor-purple
{
    background-color: rgb(63, 22, 175);
}
.color-purple
{
    color: rgb(63, 22, 175);
}
.bg-white
{
    background-color: white;
}
.bg-teal
{
  background-color: Teal;
}
.bg-blue
{
  background-color: #0057A2;
}
.bg-navy
{
    background-color: navy;
}
.color-white{
    color: white;
  }
.color-teal{
    color: teal;
}