.list_div
{
    table-layout: fixed;
    text-align: left;
    margin: 0px;
    padding: 0px;
}

.un_ordered_list
{
    display: block;
    color: black;
    margin-left: 25px;
}
.un_ordered_list li
{
    padding: 1px;
    background: transparent;
    text-align: left;
    border-radius: 0;
    font-weight: bolder;
    list-style-image:url('../images/bullet.jpg');
}
.un_ordered_list li a:hover
{
    color: orangered;
}


/*Multiple horizonatl lists*/

.horizontal_list
{
    display: inline-flex;
    list-style-type: none;
    color: black;
}

.List_item_red
{
    margin:0px;
    width: 320px;

}
.List_item_red ul
{
    margin: 0px;
    padding:5px;
    list-style-type: none;
}
.List_item_red ul li
{
    margin: 0px;
    padding: 10px;
    border-bottom: solid silver 1px;
    border-left: solid silver 1px;
    border-right: solid silver 1px;
}
.List_item_red ul li:first-child
{
    background-color: red;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    text-align: center;
    font-weight: bold;
}

.List_item_red ul li:last-child
{
    color: red;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.List_item_red ul li div
{
    margin: 3px;
    padding: 3px;
}
.List_item_red ul li:first-child:hover
{
    
    color: white;
}


.List_item_red ul li ul
{
    display: block;
    color: black;
    margin-left: 5px;
    text-align: left;
    margin-top: 0px;
}
.List_item_red ul li ul span
{
    text-align: left;
    color:red;
    font-weight: bolder;
}
.List_item_red ul li ul li
{
    padding: 1px;
    margin-left: 10px;
    margin-top: 0px;
    background: transparent;
    text-align: left;
    border-radius: 0;
    font-weight: bold;
    list-style-image:url('../images/righthand.jpg');
    border-bottom: solid silver 0px;
    border-left: solid silver 0px;
    border-right: solid silver 0px;
}
.List_item_red ul li ul li span
{
    text-align: left;
    color:red;
    font-weight: bold;
}
.List_item_red ul li ul li:first-child
{
    
    text-align: left;
}
.List_item_red ul li ul li:first-child:hover
{
    color: black;
    text-align: left;
}
.List_item_red ul li ul li:last-child
{
    
    text-align: left;
    color: black;
}
.List_item_red ul li ul li:last-child div
{
    margin: 0px;
    padding: 0px;
}
.List_item_red ul li ul li div
{
    margin: 0px;
    padding: 0px;
}



.List_item_green
{
    margin:0px;
    width: 320px;


}
.List_item_green ul
{
    margin: 0px;
    padding: 5px;
    list-style-type: none;
}
.List_item_green ul li
{
    margin: 0px;
    padding: 10px;
    border-bottom: solid silver 1px;
    border-left: solid silver 1px;
    border-right: solid silver 1px;
}
.List_item_green ul li:first-child
{
    background-color: green;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    text-align: center;
    font-weight: bold;
}
.List_item_green ul li:last-child
{
    color: green;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.List_item_green ul li div
{
    margin: 3px;
    padding: 3px;
}
.List_item_green ul li:first-child:hover
{
    
    color: white;
}
.List_item_green ul li ul
{
    display: block;
    color: black;
    margin-left: 5px;
    text-align: left;
    margin-top: 0px;
}
.List_item_green ul li ul span
{
    text-align: left;
    color:green;
    font-weight: bolder;
}
.List_item_green ul li ul li
{
    padding: 1px;
    margin-left: 10px;
    margin-top: 0px;
    background: transparent;
    text-align: left;
    border-radius: 0;
    font-weight: bold;
    list-style-image:url('../images/righthand.jpg');
    border-bottom: solid silver 0px;
    border-left: solid silver 0px;
    border-right: solid silver 0px;
}
.List_item_green ul li ul li span
{
    text-align: left;
    color:green;
    font-weight: bold;
}
.List_item_green ul li ul li:first-child
{
    
    text-align: left;
}
.List_item_green ul li ul li:first-child:hover
{
    color: black;
    text-align: left;
}
.List_item_green ul li ul li:last-child
{
    
    text-align: left;
    color: black;
}
.List_item_green ul li ul li:last-child div
{
    margin: 0px;
    padding: 0px;
}
.List_item_green ul li ul li div
{
    margin: 0px;
    padding: 0px;
}


.List_item_purple
{
    margin:0px;
    width: 320px;


}
.List_item_purple ul
{
    margin: 0px;
    padding: 5px;
    list-style-type: none;
}
.List_item_purple ul li
{
    margin: 0px;
    padding: 10px;
    border-bottom: solid silver 1px;
    border-left: solid silver 1px;
    border-right: solid silver 1px;
}
.List_item_purple ul li:first-child
{
    background-color: purple;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    text-align: center;
    font-weight: bold;
}
.List_item_purple ul li:last-child
{
    color: purple;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.List_item_purple ul li div
{
    margin: 3px;
    padding: 3px;
}
.List_item_purple ul li:first-child:hover
{
    
    color: white;
}

.List_item_purple ul li ul
{
    display: block;
    color: black;
    margin-left: 5px;
    text-align: left;
    margin-top: 0px;
}
.List_item_purple ul li ul span
{
    text-align: left;
    color:purple;
    font-weight: bolder;
}
.List_item_purple ul li ul li
{
    padding: 1px;
    margin-left: 10px;
    margin-top: 0px;
    background: transparent;
    text-align: left;
    border-radius: 0;
    font-weight: bold;
    list-style-image:url('../images/righthand.jpg');
    border-bottom: solid silver 0px;
    border-left: solid silver 0px;
    border-right: solid silver 0px;
}
.List_item_purple ul li ul li span
{
    text-align: left;
    color:purple;
    font-weight: bold;
}
.List_item_purple ul li ul li:first-child
{
    
    text-align: left;
}
.List_item_purple ul li ul li:first-child:hover
{
    color: black;
    text-align: left;
}
.List_item_purple ul li ul li:last-child
{
    
    text-align: left;
    color: black;
}
.List_item_purple ul li ul li:last-child div
{
    margin: 0px;
    padding: 0px;
}
.List_item_purple ul li ul li div
{
    margin: 0px;
    padding: 0px;
}

.List_item_blue
{
    margin:0px;
    width: 320px;


}
.List_item_blue ul
{
    margin: 0px;
    padding: 5px;
    list-style-type: none;
}
.List_item_blue ul li
{
    margin: 0px;
    padding: 10px;
    border-bottom: solid silver 1px;
    border-left: solid silver 1px;
    border-right: solid silver 1px;
}
.List_item_blue ul li:first-child
{
    background-color: blue;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    text-align: center;
    font-weight: bold;
}
.List_item_blue ul li:last-child
{
    color: blue;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.List_item_blue ul li div
{
    margin: 3px;
    padding: 3px;
}
.List_item_blue ul li:first-child:hover
{
    
    color: white;
}
.List_item_blue ul li ul
{
    display: block;
    color: black;
    margin-left: 5px;
    text-align: left;
    margin-top: 0px;
}
.List_item_blue ul li ul span
{
    text-align: left;
    color:blue;
    font-weight: bolder;
}
.List_item_blue ul li ul li
{
    padding: 1px;
    margin-left: 10px;
    margin-top: 0px;
    background: transparent;
    text-align: left;
    border-radius: 0;
    font-weight: bold;
    list-style-image:url('../images/righthand.jpg');
    border-bottom: solid silver 0px;
    border-left: solid silver 0px;
    border-right: solid silver 0px;
}
.List_item_blue ul li ul li span
{
    text-align: left;
    color:blue;
    font-weight: bold;
}
.List_item_blue ul li ul li:first-child
{
    
    text-align: left;
}
.List_item_blue ul li ul li:first-child:hover
{
    color: black;
    text-align: left;
}
.List_item_blue ul li ul li:last-child
{
    text-align: left;
    color: black;
}
.List_item_blue ul li ul li:last-child div
{
    margin: 0px;
    padding: 0px;
}
.List_item_blue ul li ul li div
{
    margin: 0px;
    padding: 0px;
}








/*Multiple horizontal lists*/

.iconstandard
{
    width: 100px;
    height: 100px;
}
.list_div_inner
{
    table-layout: fixed;
    text-align: left;
    margin: 0px;
    padding: 0px;
}
.color_purple
{
    color: purple;
}
.color_grey
{
    color: darkgrey;
}
.no_style
{
    list-style-type: none;
    margin: 0px;
}


.example_list_div
{
    margin:0px;
    width: 320px;


}
.example_list_div ul
{
    margin: 0px;
    padding: 5px;
    list-style-type: none;
    display: inline-flex;
}
.example_list_div ul li
{
    margin: 0px;
    padding: 10px;
}
.example_list_div ul li div:first-child
{
    margin: 0px;
    padding: 10px;
    background-color: azure;
    border: red solid 1px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    
}
.example_list_div ul li div span
{
    text-align: left;
  color: #1261A0 ;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  margin-left: 10px;
  font-weight: bolder;
  background-color: transparent;
  margin-top: 5px;
  margin-bottom: 5px;
    
}
.example_list_div ul li div span:hover
{
    color:orangered;
    
}
.example_list_div ul li div:last-child
{
    
    border: red solid 1px;
    padding: 10px;
}
.Exp_list_item
{
    background: transparent;
    padding: 10px;
    margin: 10px;
    border-top-right-radius: 10px;
    border-top: silver solid 1px;
    border-bottom: silver solid 1px;
    border-radius: 10px;
}
.Exp_items 
{
    text-align: left;
    list-style-type: none;
    display: inline-flex;
}
.Exp_items_blue 
{
    background-image: linear-gradient(to right, #00C9FF, #92FE9D);
    padding: 10px;
    margin: 10px;
    border: silver solid 1px;
    border-radius: 10px;
}
.Exp_items_green 
{
    background-image: linear-gradient(to right, #52c234, #061700);
    padding: 10px;
    margin: 10px;
    border: silver solid 1px;
    border-radius: 10px;
}
.Exp_items_purple 
{
    background-image: linear-gradient(to right, #ad5389, #3c1053);
    padding: 10px;
    margin: 10px;
    border: silver solid 1px;
    border-radius: 10px;
}
.Exp_items_orange 
{
    background-image: linear-gradient(to right, #C02425, #F0CB35);
    padding: 10px;
    margin: 10px;
    border: silver solid 1px;
    border-radius: 10px;
}


