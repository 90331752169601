.label-sub-heading
{
  text-align: left;
  color: #1261A0 ;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  margin-left: 0px;
  font-weight: bolder;
  background-color: transparent;
  margin-top: 5px;
  margin-bottom: 5px;
}
.label-sub-heading:hover
{
    color: orangered;
}
.label-heading
{
  color: #1261A0 ;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  margin-left: 0px;
  font-weight: bolder;
  font-size: larger;
  
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
  
}
.label-heading:hover
{
    color: orangered;
}
.label-sub-heading-item
{
  color: black;
  font-weight: bolder;
  text-align: left;
  padding:10px;
  margin-left: 0px;
  border-bottom: solid 1px black;
}
.adj_head
{
  text-align: left;
  color: #08a5da;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  margin-left: 10px;
  font-weight: bolder;
  background-color: transparent;
  margin-top: 5px;
  margin-bottom: 5px;
  border-left: orangered dashed 3px;
  padding: 10px;
}
.adj_head:hover{
  color: orangered;
  border-left : #08a5da dashed 3px;
}
.og_header{
  font-weight: bold;
  color: orangered;
}
.og_typing{
  color: black;
  font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: normal;
  margin-top: 5px;
}
.link
{
  margin-left: 25px;
  
  color: black;
}
.link:hover
{
  color: white;
}