.AdminPanelDiv
{
    width: 100%;
    background-color: bisque;
    
    
    
}
.AdminPanelTable
{
    vertical-align: top;
    width: 100%;
    text-align: left;
    
}
.AdminPanelTable tr td
{
    vertical-align: top;
    margin-right: 20px;
}
.AdminPanelTable tr td:first-child
{
    vertical-align: top;
    width: 300px;
}
.AdminPanelTable tr td div
{
    vertical-align: top;
}

.DataEntryTable
{
    margin: 10px;
    padding: 10px;
    width: 95%;
    background-color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    border: solid 1px silver;
    /*border-radius: 10px;*/
    
    
}

.DataEntryTable tr td 
{
    padding: 3px;
    
}
.DataEntryTable tr td:first-child:hover
{
    
    
}

.DataEntryTable tr td div
{
    /*border-bottom: black 4px solid;*/
    font-weight: bolder;
    padding: 10px;
    
}
.FirstDataEntryRow 
{
    
    
    
}


